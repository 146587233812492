export const ERROR_MESSAGES = {
  PASSWORD_REQUIRED: 'Please enter your password.',
  PASSWORD_MATCH: 'Passwords do not match.',
  STRONG_PASSWORD:
    'Password must be at least 8 characters long and must contain at least one number and one uppercase and lowercase character.',
  TERMS_CHECK: 'You must agree with our Terms.',
  UNAUTHORIZED: "You've entered invalid username or password. Please try again.",
  NOT_VERIFIED: 'Your account has not been verified. Please check your email for the verification link.',
}

export const strongPasswordRules = {
  pattern: {
    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g,
    message: ERROR_MESSAGES.STRONG_PASSWORD,
  },
}

export const termsRules = {
  required: { value: true, message: ERROR_MESSAGES.TERMS_CHECK },
}
