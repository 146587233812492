import FormInput from '../../components/FormInput'
import { Controller, FieldValues } from 'react-hook-form'
import * as React from 'react'
import HttpsIcon from '@mui/icons-material/Https'
import { grey } from '@/styles/palettes/general'

const rules = {
  required: { value: true, message: 'Please enter your password.' },
}

interface PasswordFieldProps<T extends FieldValues> {
  control: React.ComponentProps<typeof Controller<T>>['control']
  onCustomChange?: () => void
  customError?: boolean
  customHelperText?: string | null
  additionalRules?: React.ComponentProps<typeof Controller<T>>['rules']
  placeholder: string
  name: React.ComponentProps<typeof Controller<T>>['name']
  applyDefaultRules?: boolean
}

const PasswordField = <T extends FieldValues>({
  control,
  onCustomChange,
  customError = false,
  customHelperText,
  additionalRules,
  placeholder,
  name,
  applyDefaultRules = true,
}: PasswordFieldProps<T>) => {
  const passwordRules = applyDefaultRules
    ? {
        ...rules,
        ...additionalRules,
      }
    : additionalRules
  return (
    <FormInput<T>
      rules={passwordRules}
      control={control}
      placeholder={placeholder}
      name={name}
      InputProps={{ startAdornment: <HttpsIcon style={{ fill: grey[200] }} /> }}
      fullWidth
      type="password"
      onCustomChange={onCustomChange}
      customError={customError}
      customHelperText={customHelperText}
    />
  )
}

export default PasswordField
