import MailIcon from '@mui/icons-material/Mail'
import FormInput from '../../components/FormInput'
import { Controller, FieldValues } from 'react-hook-form'
import * as React from 'react'
import { grey } from '@/styles/palettes/general'

const ERROR_MESSAGES = {
  EMAIL_REQUIRED: 'Please enter your email address',
  EMAIL_INVALID: 'Please enter a valid email address',
}

const emailRules = {
  required: { value: true, message: ERROR_MESSAGES.EMAIL_REQUIRED },
  pattern: { value: /^[^@]+@[0-9A-z_\-.]+\.[0-9A-z]+$/g, message: ERROR_MESSAGES.EMAIL_INVALID },
}

interface EmailFieldProps<T extends FieldValues & { email: string }> {
  control: React.ComponentProps<typeof Controller<T>>['control']
  onCustomChange?: () => void
  customError?: boolean
}

const EmailField = <T extends FieldValues & { email: string }>({
  control,
  onCustomChange,
  customError = false,
}: EmailFieldProps<T>) => {
  return (
    <FormInput<T>
      rules={emailRules}
      control={control}
      // FIXME
      // @ts-expect-error https://github.com/react-hook-form/react-hook-form/issues/6726 https://github.com/react-hook-form/react-hook-form/pull/6762
      name="email"
      InputProps={{ startAdornment: <MailIcon style={{ fill: grey[200] }} /> }}
      fullWidth
      placeholder="Email address"
      onCustomChange={onCustomChange}
      customError={customError}
    />
  )
}

export default EmailField
