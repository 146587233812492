import * as React from 'react'
import TextField from '@mui/material/TextField'
import { Controller, FieldValues } from 'react-hook-form'
import { grey } from '@/styles/palettes/general'
import { Theme } from '@mui/material'

const getStyles = (theme: Theme) => ({
  '.MuiOutlinedInput-root': {
    borderRadius: '10px',
  },
  '& svg': {
    marginRight: '12px',
  },
  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'light' ? `${grey[300]}!important` : `${grey[200]}!important`,
    borderWidth: '1px !important',
  },
})

interface FormInputProps<T extends FieldValues> {
  rules?: React.ComponentProps<typeof Controller<T>>['rules']
  control: React.ComponentProps<typeof Controller<T>>['control']
  placeholder: string
  name: React.ComponentProps<typeof Controller<T>>['name']
  onCustomChange?: () => void
  customError: boolean
  customHelperText?: string | null
}

const FormInput = <T extends FieldValues>({
  rules,
  control,
  placeholder,
  name,
  onCustomChange,
  customError,
  customHelperText,
  ...other
}: FormInputProps<T> & React.ComponentProps<typeof TextField>): JSX.Element => {
  const getHelperText = (text: string | undefined) => {
    if (customHelperText !== undefined && customHelperText !== null) {
      return customHelperText
    }
    return text
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...other}
          onChange={(event) => {
            if (onCustomChange !== undefined) {
              onCustomChange()
            }
            field.onChange(event)
          }}
          error={customError || error !== undefined}
          placeholder={placeholder}
          helperText={getHelperText(error?.message)}
          sx={(theme) => getStyles(theme)}
        />
      )}
    />
  )
}

export default FormInput
